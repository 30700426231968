import React, { useEffect } from "react";

import styled from "styled-components";

import { green } from "theme/colors";

//STYLES
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${green.default};
  .container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    li {
      text-align: center;
      font-family: "Futura PT";
      font-weight: 500;
      font-size: 21px;
      line-height: 250%;
      /* or 52px */

      text-align: center;
      text-transform: uppercase;
      color: white;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
`;
//STYLES

const InstTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        institutional: { links }
      }
    },
    setColor,
    dimensions
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper>
      <div className="container">
        <ul>
          {links.map((elem, index) => {
            return (
              <li
                key={index}
                className={elem.link.url === "#" ? "disabled" : ""}
              >
                {elem.link.url === "#" ? (
                  elem.link.title
                ) : (
                  <a href={elem.link.url} target={elem.link.target}>
                    {elem.link.title}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query Tut($id: String) {
    page: wpPage(id: { eq: $id }) {
      institutional {
        links {
          link {
            target
            title
            url
          }
        }
      }
    }
  }
`;

export default InstTemplate;
